import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import { isMobile } from "react-device-detect"

import Layout from "../components/layout"
import SmallLogoFull from "../components/SmallLogoFull"
import SubPageContent from "../components/SubPageContent"
import { TextEmph } from "../components/CustomComponents"
import ContactForm from "../components/ContactForm"
import { Below } from "../components/CustomComponents.js"

const ContactItem = styled.p`
  display: grid;
  row-gap: 8px;
  margin: 20px 0 25px 0;
  text-align: center;
  .contact__details {
    display: grid;
    grid-row-gap: 20px;
  }
  .contact__colon,
  .contact__info-label {
    color: var(--color1);
  }

  .contact__item {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-column-gap: 20px;
    grid-row-gap: 8px;
    justify-content: center;
  }

  ${Below.med`
    .contact__item {
      grid-auto-flow:row;
    }
    .contact__colon {
      display: none;
  }
  `}
`

const ContactFormContainer = styled.div`
  margin: 50px 10px 0 10px;
`

const Contact = () => {
  return (
    <Layout>
      <Helmet>
        <title>Borealis | Contact</title>
      </Helmet>
      <SmallLogoFull />
      <SubPageContent title="Contact Us">
        <ContactItem>
          <div className="contact__details">
            <div className="contact__item">
              <div className="contact__info-label">
                Email<span className="contact__colon">:</span>
              </div>
              <a href="mailto:hey@borealisweb.ca?subject=Website">
                hey@borealisweb.ca
              </a>
            </div>
            <div className="contact__item">
              <div className="contact__info-label">
                Phone<span className="contact__colon">:</span>
              </div>
              {isMobile ? (
                <>
                  <a href="tel:+18072200856">(807) 220-0856</a>
                </>
              ) : (
                <>
                  <span>(807) 220-0856</span>
                </>
              )}
            </div>
          </div>
        </ContactItem>
        <ContactFormContainer>
          <h2>Request a Quote:</h2>
          <ContactForm />
        </ContactFormContainer>

        {/* <p>~ or ~</p> */}
      </SubPageContent>
    </Layout>
  )
}

export default Contact

import React from "react"
import styled from "styled-components"

import { TextEmph, ButtonStyled } from "./CustomComponents"

const Form = styled.form`
  display: grid;
  grid-auto-flow: row;
  row-gap: 20px;
`

const FormItem = styled.div`
  display: grid;
  row-gap: 5px;

  label {
    font-size: 25px;
  }

  textarea,
  input {
    border: none;
    border-bottom: 1px solid white;
    color: var(--color1);
    background-color: var(--background-color1);
    font-family: arial;
    font-size: 20px;
    padding: 0 0 5px 5px;
    margin-top: 5px;
  }
`

const HoneyPot = styled.div`
  visibility: hidden;
  height: 0;
`

const ContactForm = () => {
  return (
    <Form
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/"
    >
      <input type="hidden" name="form-name" value="contact" />
      <HoneyPot className="form__honeypot">
        <label>
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
      </HoneyPot>
      <FormItem>
        <label htmlFor="form__name" className="form__label">
          Name:&nbsp;
          <sup>
            <TextEmph>*</TextEmph>
          </sup>
        </label>
        <input
          id="form__name"
          name="name"
          type="text"
          placeholder="Enter your name"
          required
        />
      </FormItem>
      <FormItem>
        <label htmlFor="form__tel" className="form__label">
          Phone Number:&nbsp;
          <sup>
            <TextEmph>*</TextEmph>
          </sup>
        </label>
        <input
          name="phone"
          id="form__tel"
          type="tel"
          placeholder="555-555-5555"
          // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          required
        />
      </FormItem>
      <FormItem>
        <label htmlFor="form__email" className="form__label">
          Email:
        </label>
        <input
          name="email"
          id="form__email"
          type="email"
          placeholder="your@email.ca"
        />
      </FormItem>
      <FormItem>
        <label htmlFor="form__details" className="form__label">
          Details:&nbsp;
          <sup>
            <TextEmph>*</TextEmph>
          </sup>
        </label>
        <textarea
          id="form__details"
          name="details"
          rows="8"
          placeholder="Additional information for us to assist you better."
          required="true"
        />
      </FormItem>
      <div style={{ marginTop: 5, justifySelf: "end" }}>
        <sup>
          <TextEmph>*</TextEmph>
        </sup>
        &nbsp;Required
      </div>

      <ButtonStyled
        type="submit"
        className="settings__mainButton form__button"
        style={{ marginTop: 0 }}
      >
        Submit
      </ButtonStyled>
    </Form>
  )
}
export default ContactForm
